import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import BasicTable from "components/basicTable";
import List from "components/list";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "virus"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/virus.png"}) { ...eyecatchImg },
    warning: file(relativePath: { eq: "security/warning.PNG"}) { ...normalImg },
    computer_virus: file(relativePath: { eq: "security/computer-virus.webp"}) { ...normalImg },
    virusbuster: file(relativePath: { eq: "security/virusbuster.png"}) { ...normalImg },
    options: file(relativePath: { eq: "security/options.webp"}) { ...normalImg },
    ransom: file(relativePath: { eq: "security/ransom.webp"}) { ...normalImg },
    windowsdefender: file(relativePath: { eq: "security/windows-defender.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ウイルスバスターは必要か？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="ウイルスバスターって必要？" mdxType="Image" />
    <p>{`Windows 10以降では標準ウイルス対策ソフトとして`}<em parentName="p">{`Windows Defender`}</em>{`というソフトウェアがWindows OSに付属している。このウイルス検知能力は十分に高いため、ウイルスバスターを購入しなくてもセキュリティ対策として十分と考えるのは自然である。`}</p>
    <p>{`しかし、本当にそうだろうか？本ページでは、Windows Defenderによるウイルス対策の現状認識を行い、`}<strong parentName="p"><em parentName="strong">{`マイクロトレンド社のウイルスバスタークラウドを導入すべきかを検討`}</em></strong>{`する。`}</p>
    <p>{`なお、ウイルスバスターはセキュリティソフト会社であるトレンドマイクロの商標であり、Nortonやカスペルスキーなど数ある`}<em parentName="p">{`セキュリティ対策ソフト`}</em>{`の一つであるが、`}<strong parentName="p"><em parentName="strong">{`日本でのシェアが圧倒的であり、他社にはない優れたポイントもあるためウイルスバスタークラウドに焦点を絞り解説`}</em></strong>{`する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "そもそもウイルスとは何か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%82%82%E3%81%9D%E3%82%82%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%81%A8%E3%81%AF%E4%BD%95%E3%81%8B%EF%BC%9F",
        "aria-label": "そもそもウイルスとは何か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`そもそもウイルスとは何か？`}</h2>
    <Image {...props} name="computer_virus" alt="コンピューターウイルス" mdxType="Image" />
    <p>{`ウイルスというと何か有機的な生物を想像するが、実際は`}<strong parentName="p"><em parentName="strong">{`コンピュータのシステムに悪影響を及ぼす目的で作られた悪意のあるプログラムの一種`}</em></strong>{`であり、無機的なものである。種類はあるが、基本的には有害なものか無害なものかだけで判断がなされ、有害であればウイルスと判定される。`}</p>
    <p>{`ただし、悪さの仕方によりある程度の分類がなされており代表的なウイルスの種類や用語は次のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>種類</th>
      <th>説明</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>スパイウェア</td>
      <td>ユーザーの許可なく個人情報を収集し、第三者に送信するプログラム。</td>
    </tr>
    <tr>
      <td>トロイの木馬</td>
      <td>正規のソフトウェアに見せかけて侵入し、内部で不正な動作を行うプログラム。</td>
    </tr>
    <tr>
      <td>ランサムウェア</td>
      <td>ユーザーのファイルを暗号化し、解除のために身代金を要求する悪意のあるプログラム。</td>
    </tr>
    <tr>
      <td>ウイルス</td>
      <td>ウイルスの分類の中でのウイルス。自己複製を行い、他のプログラムに感染し、データを破壊したり不正な動作を引き起こす。</td>
    </tr>
    <tr>
      <td>ワーム</td>
      <td>ネットワークを介して自己複製し、システムに広がるプログラムで、感染速度が非常に速い。</td>
    </tr>
    <tr>
      <td>マルウェア</td>
      <td>悪意のあるソフトウェア全般を指し、ウイルス、スパイウェア、ランサムウェアなどを含む。</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`ウイルスは自己複製という表現が分かりにくいが、感染対象となるパソコンが増えていくイメージであり、例えば`}<em parentName="p">{`ILOVEYOU`}</em>{`という2000年のウイルスは、`}<strong parentName="p"><em parentName="strong">{`メールの添付ファイルを開くと感染者のアドレス帳に登録されているすべての連絡先にも同じウイルスプログラムを自動送信`}</em></strong>{`して被害台数は5000万台を超えた。`}</p>
    <p>{`ランサムウェアは比較的新しいタイプのウイルスで、現金を振り込まない限りはパソコンをロックして使用できなくする。同時に個人情報を盗む凶悪なタイプも多く、KADOKAWAなど多くの日本企業が被害を受けている。`}<strong parentName="p"><em parentName="strong">{`個人で感染すると一言でいうと地獄`}</em></strong>{`である。`}</p>
    <Image {...props} name="ransom" caption="ランサムウェアに感染した様子" mdxType="Image" />
    <p>{`これら悪意のある`}<strong parentName="p"><em parentName="strong">{`ウイルスプログラムはインストールしたフリーソフトに紛れていたり、Emailの添付ファイルに潜んでいることが多い`}</em></strong>{`。迂闊なクリックが命取りである。`}</p>
    <SmallBox type="word" text="ウイルスはマルウェアの一種" mdxType="SmallBox">
日本ではウイルスで通っているが、正式にはマルウェアという言い方が正しい。マルウェアはMalicious(悪意のある)Softwareから来た造語で、悪質なプログラムの総称を言う。<br />
ウイルス対策ソフトはウイルスを含めマルウェアを検出・除去するソフトと言う事ができる。
    </SmallBox>
    <h2 {...{
      "id": "Windows-Defenderの機能と現状",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Windows-Defender%E3%81%AE%E6%A9%9F%E8%83%BD%E3%81%A8%E7%8F%BE%E7%8A%B6",
        "aria-label": "Windows Defenderの機能と現状 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows Defenderの機能と現状`}</h2>
    <Image {...props} name="windowsdefender" alt="Windows Defender" mdxType="Image" />
    <p><strong parentName="p"><em parentName="strong">{`Windows Defenderは、Windowsに標準で搭載されているウイルス対策機能`}</em></strong>{`で、上述したマルウェアからパソコンを保護するために設計されている。`}</p>
    <p>{`Windows Defenderが提供する日常的なパソコン利用における有用なセキュリティ対策としては、フィッシング対策、ファイアウォールの強化、スパイウェア対策、自動更新による最新の脅威への対応など、ウイルス対策を含めた広範なセキュリティ対策が挙げられ、`}<strong parentName="p"><em parentName="strong">{`日常的なパソコン利用においては、Windows Defenderだけでも一定のレベルの保護は期待できる`}</em></strong>{`。`}</p>
    <p>{`例えばフィッシングとは、偽サイトに誘導してIDとPASSWORDをだまし取ろうとする手口であり、例えば三井住友銀行の偽サイトへメールで誘導するといったパターンが多いが、Windows Defenderはサイトを開くタイミングでこれを検出して、次のような警告を表示してくれる。`}</p>
    <Image {...props} name="warning" caption="フィッシング詐欺に対する警告" mdxType="Image" />
    <p><strong parentName="p"><em parentName="strong">{`広範なセキュリティ対策を無料の標準ソフトで防ぐことができるので、多くの人がWindows Defenderで十分と考えるのは自然`}</em></strong>{`である。さらにWindows Defenderは他のセキュリティ対策ソフトと比べても遜色のないウイルス検出率を誇るという結果もある。`}</p>
    <p>{`しかし、本当にそうであろうか？次は国内で大半のシェアを占めているトレンドマイクロのウイルスバスタークラウドがカバーできる範囲について説明する。`}</p>
    <h2 {...{
      "id": "ウイルスバスタークラウドは日本の脅威に強い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%83%90%E3%82%B9%E3%82%BF%E3%83%BC%E3%82%AF%E3%83%A9%E3%82%A6%E3%83%89%E3%81%AF%E6%97%A5%E6%9C%AC%E3%81%AE%E8%84%85%E5%A8%81%E3%81%AB%E5%BC%B7%E3%81%84",
        "aria-label": "ウイルスバスタークラウドは日本の脅威に強い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウイルスバスタークラウドは日本の脅威に強い`}</h2>
    <Image {...props} name="virusbuster" caption="日本の脅威に強いウイルスバスター" mdxType="Image" />
    <p>{`ウイルスバスタークラウドはトレンドマイクロという会社の有料セキュリティ対策ソフトである。`}<strong parentName="p"><em parentName="strong">{`国内セキュリティソフトで40%程度の圧倒的なシェアを誇る`}</em></strong>{`ため、ウイルスバスターがセキュリティ対策ソフトの代名詞だと思っている人も多い。またトレンドマイクロは日本のプライム上場企業であり、開発部隊も日本にある。`}</p>
    <p>{`この`}<strong parentName="p"><em parentName="strong">{`高いシェアと国内開発体制により日本の脅威に強いということがウイルスバスターの最大の特長`}</em></strong>{`である。`}</p>
    <p>{`ウイルス検知で重要なことは未知の脅威への対応、すなわち新しいウイルスや詐欺の手法が広まっていくときにどれだけ早く対応できるかである。`}</p>
    <p>{`ここで、シェアが高い分日本を狙ったこれまでの攻撃の兆候を蓄積していること、ユーザからの情報を日本語で受けられることなどから日本でのウイルスへの対応が極めて強くなる。`}</p>
    <p>{`一方でWindows DefenderはMicrosoftのため、USに開発部隊がおり、またウイルス情報も英語圏のものが中心となるため日本は後手となりがちである。`}</p>
    <BasicTable mdxType="BasicTable">
      <thead>
    <tr>
      <th>項目</th>
      <th>Windows Defender</th>
      <th>ウイルスバスタークラウド</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>提供元</td>
      <td>Microsoft</td>
      <td>トレンドマイクロ</td>
    </tr>
    <tr>
      <td>価格</td>
      <td>無料</td>
      <td>有料（サブスクリプション）</td>
    </tr>
    <tr>
      <td>未知への脅威</td>
      <td>遅れがち</td>
      <td>日本での迅速な対応</td>
    </tr>
    <tr>
      <td>追加機能</td>
      <td>基本的なセキュリティ機能</td>
      <td>パスワードマネージャ、電話サポートなど多数の追加機能</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`他はセキュリティ機能が一通り揃っているのでウイルスバスタークラウドでセキュリティの一元管理が行ないやすいことぐらいであるが、多くは代替手段があり、あまり重要視しなくても良いと思われる。`}</p>
    <h3 {...{
      "id": "他のセキュリティソフトはどうか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BB%96%E3%81%AE%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%82%BD%E3%83%95%E3%83%88%E3%81%AF%E3%81%A9%E3%81%86%E3%81%8B%EF%BC%9F",
        "aria-label": "他のセキュリティソフトはどうか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`他のセキュリティソフトはどうか？`}</h3>
    <p>{`ウイルス検知率のランキングがドイツの第三者機関である、`}<a parentName="p" {...{
        "href": "AV-Test.org"
      }}>{`AV-Test.org`}</a>{`などに行けば公表されている。これで`}<em parentName="p">{`ノートンアンチウイルス`}</em>{`や`}<em parentName="p">{`McAfee`}</em>{`、`}<em parentName="p">{`Avast`}</em>{`、`}<em parentName="p">{`カスペルキー`}</em>{`など多くのセキュリティ対策ソフトが評価されていることが分かり、これらは比較検討候補ということになる。`}</p>
    <p>{`ただ、これは特定の地域ごとにみたランキングではないかつ、テスト項目に偏りがないとも言えないためエビデンスとしては頼りないだろう。更にWindows Defenderと大きな差はないことから、日本でのシェアが大きくなく開発部隊もいないセキュリティ対策ソフトに優位性を見出すことはできない。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`有料のウイルス対策ソフトを導入するならばトレンドマイクロ一択というのが私の結論`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "ウイルスバスターを導入すべきか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%83%90%E3%82%B9%E3%82%BF%E3%83%BC%E3%82%92%E5%B0%8E%E5%85%A5%E3%81%99%E3%81%B9%E3%81%8D%E3%81%8B%EF%BC%9F",
        "aria-label": "ウイルスバスターを導入すべきか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウイルスバスターを導入すべきか？`}</h2>
    <Image {...props} name="options" alt="ウイルスバスターを導入すべきかをコストと天秤にかけて思案している様子" mdxType="Image" />
    <p>{`未知の脅威やフィッシング詐欺に対する日本での対処率などエビデンスのあるデータはどこにも掲載されていないため、ウイルスバスターがWindows Defenderと比べてどれほどの優位性を持つかを検証できる人はいない。`}</p>
    <p>{`ここで重要なのは被害想定額とコストとのバランスである。`}<strong parentName="p"><em parentName="strong">{`オンラインショッピングやネットバンキングの利用頻度が高い場合、万が一の被害リスクと比較すれば、コストパフォーマンスは良好`}</em></strong>{`ではないかと思われる。逆に失うものがほとんどない人は悪くてパソコン内のデータが消えるだけであろう。`}</p>
    <p>{`FacebookやInstagram、TwitterなどのSNSやEmailなどからのフィッシング詐欺リスクは高まり、ぼんやりしている時にうっかりID/Passwordを偽サイトに入れてしまうことや、ウイルス付き添付ファイルを開く危険性は私とてゼロではない。`}<strong parentName="p"><em parentName="strong">{`年間5000円程度のコストで、クレジットカード情報や金銭に関するリスクをさらに軽減できることは、安心感の面で大きな価値がある`}</em></strong>{`だろう。`}</p>
    <p>{`最後に、通常より割引価格で購入できるリンクがあるので、それを張って終わりとしたい。`}</p>
    <SmallBox type="word accent" text="公式オンラインショップ特別販売ページへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=517737.10000611&type=3&subid=0" target="_blank" rel="nofollow noopener">ウイルスバスタークラウド</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=517737.10000611&type=3&subid=0" />
    </SmallBox>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      